
import { async } from "@firebase/util";
import { getAuth } from "firebase/auth";
import { child, DataSnapshot, getDatabase, onValue, push, ref, remove, update } from "firebase/database";
import { get } from "store";
import { loginAnonymous } from "../_actions/userAction";
import apiClient from "./axios";
import { initializeApp } from "@firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyC7Zmuhgz9yHHsC10Q6faHi0ue6AZWXzNI",
    authDomain: "textiledesignbank-354907.firebaseapp.com",
    projectId: "textiledesignbank-354907",
    storageBucket: "textiledesignbank-354907.appspot.com",
    messagingSenderId: "129776358608",
    appId: "1:129776358608:web:5ea391128e2ae6a55b3ad5",
    measurementId: "G-VZX1L89L5S",
    databaseURL: "https://textiledesignbank-354907-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const database = getDatabase();
const auth = getAuth();

export const addUserCartForAnonymous = async (product) => {
    if (!auth.currentUser) {
        await loginAnonymous();
    }

    const updates = {};
    updates[`/users/${auth.currentUser.uid}/carts/${product.product_id}`] = product;
    try {
        await update(ref(database), updates);
    } catch (error) {
        console.log(error)
    }
}

export const getUserCartForAnonymous = async () => {
    if (!auth.currentUser) {
        await loginAnonymous();
    }
    const data = await new Promise((resolve, reject) => {
        try {
            onValue(ref(database, `/users/${auth.currentUser.uid}/carts/`), (snapshot) => {
                const carts = [];
                snapshot.forEach(item => {
                    carts.push(item.val())
                })
                resolve(carts);
            });
        } catch (error) {
            reject(error);
        }
    })

    return data;
}

export const deleteAnonymousCarts = () => {
    if (!auth.currentUser) {
        return;
    }

    remove(ref(database, `/users/${auth.currentUser.uid}/carts/`));
}

export const deleteAnonymousCart = async (proudct_id) => {
    if (!auth.currentUser) {
        return;
    }

    await remove(ref(database, `/users/${auth.currentUser.uid}/carts/${proudct_id}`));
}

export const sendAnonymousCartsForUser = async () => {
    const carts = [];
    const cartsData = await getUserCartForAnonymous()
    cartsData.forEach(cart => {
        carts.push(cart.product_id)
    })

    if (carts.length <= 0) {
        return;
    }

    await apiClient.post(`/web/carts`, {
        carts
    });

    deleteAnonymousCarts();
}

