/** @format */

import { REGISTER_USER, LOGIN_USER, DUPLICATE_NICKNAME_CHECK, COUNT_BASKET_ITEM } from '../_actions/types';

const initialState = {
  totalCartCount:0,
};


export const loginUser = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loginSuccess: action.payload };
    default:
      return state;
  }
};

export const registerUser = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return { ...state, loginSuccess: action.payload };
    default:
      return state;
  }
};

export const duplicateNicknameCheck = (state = {}, action) => {
  switch (action.type) {
    case DUPLICATE_NICKNAME_CHECK:
      return { ...state, is_duplicate: action.payload };
    default:
      return state;
  }
};

export const getUserInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};