/** @format */
import { useDispatch } from 'react-redux';

import Store from 'store';
import store from '../store';
import { REGISTER_USER, LOGIN_USER, DUPLICATE_NICKNAME_CHECK, COUNT_BASKET_ITEM } from './types';
import apiClient from '../_utils/axios';

import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from "@firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC7Zmuhgz9yHHsC10Q6faHi0ue6AZWXzNI",
  authDomain: "textiledesignbank-354907.firebaseapp.com",
  projectId: "textiledesignbank-354907",
  storageBucket: "textiledesignbank-354907.appspot.com",
  messagingSenderId: "129776358608",
  appId: "1:129776358608:web:5ea391128e2ae6a55b3ad5",
  measurementId: "G-VZX1L89L5S",
  databaseURL: "https://textiledesignbank-354907-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const auth = getAuth();

const registerUser = async (dataToSubmit) => {
  try {
    const response = await apiClient.post('/auth/user/register', dataToSubmit);
    console.log('response: ', response);
    if (response) {
  
      return {
        type: LOGIN_USER,
        payload: response.data,
      };
    }
    return {
      type: REGISTER_USER,
      payload: response.data,
    };
  } catch (err) {
    return console.log(err);
  }
};

const loginUser = async (dataToSubmit) => {
  try {
    const response = await apiClient.post('/auth/login', dataToSubmit);
    if (response) {
      const { accessToken } = response.data;
      if (accessToken) {
        Store.set('authdata', 1);
        Store.set('accessToken', accessToken);
        Store.set('userData', response.data);
      }

      return {
        type: LOGIN_USER,
        payload: response.data,
      };
    }
    return {
      type: LOGIN_USER,
      payload: response,
    };
  } catch (err) {
    console.log('userAction/loginUser : ', err);
    return;
  }
};

const duplicateNicknameCheck = async (dataToSubmit) => {
  try {
    const response = await apiClient.post('/auth/nickname/check', dataToSubmit);

    return {
      type: DUPLICATE_NICKNAME_CHECK,
      payload: response,
    };
  } catch (err) {
    return console.log(err);
  }
};

const duplicateEmailCheck = async (dataToSubmit) => {
  try {
    const response = await apiClient.post('/auth/email/check', dataToSubmit);

    // console.log('response',response)
    return {
      payload: response,
    };
  } catch (err) {
    return console.log(err);
  }
};

const loginAnonymous = async () => {
  try {
    await signInAnonymously(auth);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(`[${errorCode}] : ${errorMessage}`)
  };
}

const countBasketItem = async () => {
  try {
    const res = await apiClient.get('/web/cart/count');
    return res;
  } catch (error) {
    console.log(error);
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(`[${errorCode}] : ${errorMessage}`)
  };
}

export { registerUser, loginUser,duplicateEmailCheck, duplicateNicknameCheck, loginAnonymous, countBasketItem };
