import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Store from 'store';

import translationKo from './translation.ko';
import translationEn from './translation.en';

const resource = {
  kr: {
    translation: translationKo,
  },
  en: {
    translation: translationEn,
  },
  ur: {
    translation: translationEn,
  },
};

i18n.use(initReactI18next).init({
  resources: resource,
  lng: Store.get('lang')
    ? Store.get('lang')
    : navigator.language === 'ko-KR' || navigator.language === 'ko'
    ? 'kr'
    : 'en',
  fallbackLng: Store.get('lang')
    ? Store.get('lang')
    : navigator.language === 'ko-KR' || navigator.language === 'ko'
    ? 'kr'
    : 'en',

  debug: false,
  keySeparator: '.', // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
