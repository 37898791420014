import { combineReducers } from 'redux';
import { loginUser, registerUser, duplicateNicknameCheck, getUserInfo } from './userReducer';

const rootReducer = combineReducers({
  loginUser,
  registerUser,
  duplicateNicknameCheck,
  getUserInfo
});

export default rootReducer;