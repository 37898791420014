import { useEffect } from "react";
import ReactGA from "react-ga";
import Store from 'store';

const Analytics = () => {
    const pathName = window.location.pathname;
    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            ReactGA.initialize("UA-212147732-1");
            ReactGA.set({ page: pathName });
            ReactGA.pageview(pathName);

            const userData = Store.get('userData');
            if (userData) {
                ReactGA.set({ userId: userData.id });
            }
        }
    }, [pathName]);
    return <></>;
}

export default Analytics;